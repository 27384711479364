var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
var tablet = ref.tablet;
var mobile = ref.mobile;
return [_c('aside',{staticClass:"shows__filters-mobile"},[_c('v-drawer',{attrs:{"visible":_vm.filtersVisible},on:{"update:visible":function($event){_vm.filtersVisible=$event}}},[_c('filters-mobile',{attrs:{"default-filters":_vm.queryParams,"count":_vm.params.count},on:{"close":function($event){_vm.filtersVisible = false},"apply-filters":_vm.applyFilters}})],1)],1),(!mobile)?_c('aside',{staticClass:"shows__bulk-edit"},[_c('v-drawer',{attrs:{"position":"right","without-background":"","visible":_vm.bulkEditVisible},on:{"update:visible":function($event){_vm.bulkEditVisible=$event}}},[_c('shows-bulk-edit-dialog',{attrs:{"shows":_vm.selectedShowsEdit},on:{"close":function($event){_vm.bulkEditVisible = false},"delete":_vm.deleteShows,"submitted":_vm.editShowsSubmit}})],1)],1):_vm._e(),_c('div',{staticClass:"shows"},[_c('div',{ref:"showsTopWrapper",staticClass:"shows__top-wrapper"},[_c('div',{staticClass:"shows__header"},[_c('v-page-title',{staticClass:"shows__title"},[_vm._v(" Мои показы "),_c('span',{staticClass:"shows__count"},[_vm._v(_vm._s(_vm.params.count))])]),(desktop)?_c('v-suggest-search',{staticClass:"shows__searchrow",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCustomerPage(item.id)}}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"shows__buttons"},[(!mobile)?_c('v-button',{staticClass:"shows__button shows__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"shows__button-icon shows__button-icon--filters"}),_vm._v(" "+_vm._s(desktop ? '' : 'Фильтры')+" ")],1):_vm._e(),_c('v-button',{staticClass:"shows__button shows__button--add",attrs:{"primary":""},on:{"click":_vm.addShow}},[_c('v-icon-plus',{staticClass:"shows__icon-plus"}),_vm._v(" Добавить показ ")],1)],1)],1),(mobile)?_c('v-button',{staticClass:"shows__button shows__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"shows__button-icon shows__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e(),(!desktop)?_c('v-suggest-search',{staticClass:"shows__search",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCustomerPage(item.id)}}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"shows__content"},[(!mobile)?[_c('shows-table',{attrs:{"table-height":_vm.tableHeight,"infinite-loading":_vm.infiniteLoading,"list":_vm.list,"infinite-id":_vm.infiniteId},on:{"edit":_vm.editShow,"next-page-request":_vm.nextPageRequest,"change-sort":_vm.applyFilters,"bulk-edit":_vm.bulkEdit}})]:[_vm._l((_vm.list),function(show){return _c('shows-list-item',_vm._b({key:show.id,on:{"delete":function($event){return _vm.deleteShows([show.id])},"pending-edit":function($event){return _vm.editShow(show)},"edit":function($event){return _vm.editShowMobile(show)}}},'shows-list-item',show,false))}),_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"no-more",fn:function(){return [_c('span')]},proxy:true},{key:"no-results",fn:function(){return [_c('span')]},proxy:true}],null,true)})]],2),_c('shows-create-dialog',{attrs:{"visible":_vm.editDialogVisible,"show-data":_vm.showData},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"submitted":_vm.editShowsSubmit}}),_c('shows-edit-pending-dialog',{attrs:{"visible":_vm.editPendingDialogVisible,"draft-data":_vm.showDraftData,"show-data":_vm.showData},on:{"update:visible":function($event){_vm.editPendingDialogVisible=$event},"submitted":_vm.editShowsSubmit}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }