<template>
  <v-filter-modal
    class="shows-filters-mobile"
    :active-menu-item.sync="activeMenuItem"
    :menu="$options.NAME_MENU_ITEM"
    :count="filtersCount"
    @apply="applyFilters"
    @reset="resetFilters"
    @close="closeFilters"
  >
    <div v-if="!activeMenuItem" class="shows-filters-mobile__sorts">
      <div class="shows-filters-mobile__tabs-wrapper-title">СОРТИРОВАТЬ ПО</div>
      <div class="shows-filters-mobile__radio-wrapper" body-scroll-lock-ignore>
        <v-radio
          v-for="option in $options.SORT_OPTIONS"
          :key="option.label"
          v-model="filtersParams.orderBy"
          class="shows-filters-mobile__radio"
          :value="option.value"
          >{{ option.label }}</v-radio
        >
      </div>
    </div>

    <div class="shows-filters-mobile__menu">
      <v-menu v-if="!activeMenuItem" v-model="activeMenuItem">
        <v-menu-item name="category">
          Категория
          <span class="shows-filters-mobile__menu-item-preview"> {{ preview.category }} </span>
        </v-menu-item>
        <v-menu-item name="price">
          Цена
          <span class="shows-filters-mobile__menu-item-preview"> {{ pricePreview }} </span>
        </v-menu-item>
        <v-menu-item v-if="isRoleAdmin" name="agent">
          Агент
          <span class="shows-filters-mobile__menu-item-preview">
            {{ preview.agent }}
          </span>
        </v-menu-item>
        <v-menu-item name="date">
          Дата показа
          <span class="shows-filters-mobile__menu-item-preview"> {{ datePreview }} </span>
        </v-menu-item>
        <v-menu-item name="status">
          Статус
          <span class="shows-filters-mobile__menu-item-preview"> {{ preview.status }} </span>
        </v-menu-item>
      </v-menu>
    </div>

    <div v-if="!agentOptionsLoading" class="shows-filters-mobile__sections-wrapper">
      <div v-show="activeMenuItem === 'category'" class="shows-filters-mobile__select">
        <filters-select v-model="selectedCategory" :options="categoryOptions" class="shows-filters-mobile__list-item" />
      </div>
      <div v-show="activeMenuItem === 'agent'" class="shows-filters-mobile__select">
        <filters-select v-model="selectedAgent" :options="agentOptions" class="shows-filters-mobile__list-item" />
      </div>
      <div v-show="activeMenuItem === 'status'" class="shows-filters-mobile__select">
        <filters-select
          v-model="selectedShowStatus"
          :options="statusesOptions"
          class="shows-filters-mobile__list-item"
        />
      </div>

      <v-filter-price
        v-show="activeMenuItem === 'price'"
        :price-gte.sync="filtersParams.priceGte"
        :price-lte.sync="filtersParams.priceLte"
      />
      <v-date-publication
        v-show="activeMenuItem === 'date'"
        :date-from.sync="filtersParams.dateFrom"
        :date-to.sync="filtersParams.dateTo"
      />
    </div>
  </v-filter-modal>
</template>

<script>
import VFilterModal from '@/components/common/VFilterModal.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VRadio from '@/components/common/VRadio.vue'
import FiltersSelect from '@/components/Agent/AgentsSelect.vue'
import { advertsService, agentsService } from '@/services/http'
import { SORT_OPTIONS_MAP } from '@/constants/showsOptions'
import { SHOWS_STATUSES_OPTIONS } from '@/constants/showsStatuses'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatDatePreview, formatMoney } from '@/utils/formatters'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VDatePublication from '@/components/common/VDatePublication.vue'
import VFilterPrice from '@/components/common/VFilterPrice.vue'

function generateFilters() {
  return {
    categoryId: undefined,
    agentId: undefined,
    orderBy: '-show_date',
    dateTo: undefined,
    dateFrom: undefined,
    priceLte: undefined,
    priceGte: undefined,
    status: undefined
  }
}

function generatePreview() {
  return {
    agent: null,
    category: null,
    status: null
  }
}

const NAME_MENU_ITEM_MAP = {
  agent: 'Агент',
  price: 'Цена',
  category: 'Категории',
  date: 'Дата показа',
  status: 'Статус'
}

export default {
  SORT_OPTIONS: SORT_OPTIONS_MAP,
  NAME_MENU_ITEM: NAME_MENU_ITEM_MAP,
  SHOWS_STATUSES_OPTIONS,
  name: 'ShowsFiltersMobile',
  components: {
    VFilterPrice,
    VDatePublication,
    VFilterModal,
    VRadio,
    VMenu,
    VMenuItem,
    FiltersSelect
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      agentOptions: [],
      categoryOptions: [],
      activeMenuItem: undefined,
      agentOptionsLoading: false,
      categoryOptionsLoading: false,
      filtersParams: generateFilters(),
      preview: generatePreview()
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    selectedCategory: {
      get() {
        return { id: this.filtersParams.categoryId }
      },
      set(category) {
        this.filtersParams.categoryId = category?.id
        this.preview.category = category?.name
      }
    },
    selectedAgent: {
      get() {
        const a = this.agentOptions.find(agent => agent.profileId === this.filtersParams.agentId)?.id
        return { id: a }
      },
      set(agent) {
        this.filtersParams.agentId = agent?.profileId
        this.preview.agent = agent?.name
      }
    },
    selectedShowStatus: {
      get() {
        return { id: this.filtersParams.status }
      },
      set(status) {
        this.filtersParams.status = status?.id
        this.preview.status = status?.name
      }
    },
    statusesOptions() {
      return SHOWS_STATUSES_OPTIONS.map(option => {
        return { id: option.value, name: option.label }
      })
    },
    pricePreview() {
      if (this.filtersParams.priceGte || this.filtersParams.priceLte) {
        return `${formatMoney(this.filtersParams.priceGte) || '0'} - ${formatMoney(this.filtersParams.priceLte) ||
          '...'} ₽`
      }
      return null
    },
    datePreview() {
      return formatDatePreview(this.filtersParams.dateFrom, this.filtersParams.dateTo)
    },
    filtersCount() {
      const text = pluralizeNumeral(this.count, 'показ', 'показа', 'показов')
      return `${this.count} ${text}`
    }
  },
  watch: {
    defaultFilters: {
      immediate: true,
      handler({ orderBy, page, ...newFilters }) {
        this.filtersParams = { ...this.filtersParams, ...newFilters }
      }
    },
    agentOptions() {
      this.preview.agent = this.agentOptions.find(agent => agent.profileId === this.filtersParams.agentId)?.name
    },
    categoryOptions() {
      this.preview.category = this.categoryOptions.find(category => category.id === this.filtersParams.categoryId)?.name
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectCategory()
    this.selectShowsStatus()
  },
  methods: {
    selectShowsStatus() {
      this.preview.status = SHOWS_STATUSES_OPTIONS.find(status => status.value === this.filtersParams.status)?.label
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectCategory() {
      this.categoryOptionsLoading = true
      return advertsService
        .selectCategory()
        .then(categories => {
          this.categoryOptions = categories
        })
        .finally(() => {
          this.categoryOptionsLoading = false
        })
    },
    changeOrder(orderBy) {
      this.filtersParams.orderBy = orderBy
    },
    closeFilters() {
      this.$emit('close')
    },
    resetFilters() {
      this.filtersParams = generateFilters()
      this.preview = generatePreview()
    },
    applyFilters() {
      this.$emit('apply-filters', this.filtersParams)
    }
  }
}
</script>
